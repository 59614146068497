import React, { useState } from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import styled, { keyframes } from 'styled-components'
import theme from '../../config/theme'

import CheckBox from './checkbox'

const WrappedForm = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: fit-content(100%);
  grid-gap: 1rem;
`

const Input = styled.div`
  position: relative;
  display: block;
  input {
    font-family: inherit;
    width: 100%;
    padding: 1.8rem .8rem .5rem .8rem;
    font-size: 1.1rem;
    border-radius: .5rem;
    border: 0;
    background-color: rgba(255, 255, 255, .1);
    transition: all 200ms ease-in-out;
    &:hover {
      background-color: rgba(255, 255, 255, .2);
    }
    &:focus,
    &:active,
    &.filled {
      background-color: white !important;
      outline: 0;
      border-color: ${theme.colors.primary};
      + label {
        color: ${theme.colors.primary};
      }
    }
  }
  label {
    font-weight: bold;
    position: absolute;
    top: .5rem;
    left: .8rem;
    font-size: .8rem;
    color: rgba(255, 255, 255, .5);
  }
  span {
    font-size: .6rem;
    position: absolute;
    color: red;
    bottom: .5rem;
    right: .5rem;
    opacity: .5;
  }
`

const Submit = styled.button`
  max-width: 20rem;
  display: inline-block;
  padding: .5rem 2rem;
  border: .1em solid transparent;
  background-color: ${props => props.invert ? theme.colors.primary : theme.colors.secondary };
  color: ${props => props.invert ? '#fff': theme.colors.primary };
  font-weight: bold;
  border-radius: .5rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: all 200ms ease-in-out;
  margin: 0 auto;
  :hover {
    background-color: ${props => props.invert ? theme.colors.primary50 : theme.colors.secondary50};
  }
`

const dots = keyframes`
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: rgba(0,0,0,.7);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 rgba(0,0,0,.7),
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 rgba(0,0,0,.7),
      .5em 0 0 rgba(0,0,0,.7);
  }
`

const SubmitText = styled.p`
  font-size: .8rem;
  font-weight: bold;
  color: ${theme.colors.primary};
  text-align: center;
  :after {
    content: '.';
    animation: ${dots} 1s steps(5, end) infinite;
  }
`

const DemoForm = ({location}) => {
  const catala = /ca/.test(location.pathname)
  const [submit, setSubmit] = useState(false)
  const handleChange = event  => {
    if (event.target.value !== '') {
      event.target.classList.add('filled')
    } else {
      event.target.classList.remove('filled')
    }
  }
  return (
    <WrappedForm
      action={catala ? 'https://checkout.jornada.io/submit-demo.php?curl=dssO283B' : 'https://checkout.jornada.io/submit-demo.php?curl=TrKdLzWi'}
      method="post"
      onSubmit={() => {
        setSubmit(!submit)
        trackCustomEvent({
          category: "Demo Form",
          action: "submit_demo_btn",
        })
      }}
    >
      <Input>
        <input
          id="name"
          name="name"
          type="text"
          onChange={handleChange}
          onFocus={() => {
            trackCustomEvent({
              category: "Demo Form",
              action: "focus_name_input",
            })
          }}
          required
        />
      <label htmlFor="name">{catala ? 'Nom' : 'Nombre Completo'}</label>
      </Input>
      <Input>
        <input
          id="company"
          name="company"
          type="text"
          onChange={handleChange}
        />
        <label htmlFor="company">Empresa</label>
      </Input>
      <Input>
        <input
          id="phone"
          name="phone"
          type="tel"
          onChange={handleChange}
        />
      <label htmlFor="phone">{catala ? 'Telèfon' : 'Teléfono'}</label>
      </Input>
      <input
        id="email"
        name="email"
        type="email"
        style={{ display: 'none'}}
      />
      <Input>
        <input
          id="email_r"
          name="email_r"
          type="email"
          onChange={handleChange}
          required
        />
      <label htmlFor="email_r">{catala ? 'Correu electrònic' : 'Correo electrónico'}</label>
      </Input>
      <CheckBox invert required>
        {catala ? (
          <>
            Accepto les&nbsp;<a href="/nota-legal" target="_blank">condicions d'ús i privacitat</a>
          </>
        ) : (
          <>
            Acepto las&nbsp;<a href="/nota-legal" target="_blank">condiciones de uso y privacidad</a>
          </>
        )}
      </CheckBox>
        <Submit type="submit">{catala ? 'Començar' : 'Empezar'}</Submit>
        {submit && (
          <SubmitText>{catala ? 'Començant' : 'Empezando'}</SubmitText>
        )}
    </WrappedForm>
  );
};

export default DemoForm