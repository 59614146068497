import React from 'react'
import styled from 'styled-components'
import theme from '../../config/theme'

const Wrapper = styled.div`
  font-size: .8rem;
  .check__input {
    display: block;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(1px);
    white-space: nowrap;
    position: absolute;
    &:checked {
      ~ [class*="decor"] {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  .check__decor {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    border: .1em solid transparent;
    background-color: rgba(255, 255, 255, .1);
    border-radius: .4rem;
    position: relative;
    cursor: pointer;
    margin-right: .5rem;
    border: .1em solid ${props => props.invert ? 'transparent' : theme.colors.primary };
  }
  .check__decor:after {
    display: block;
    content: '';
    height: .5em;
    width: .8em;
    border-bottom: .1em solid ${props => props.invert ? '#fff' : theme.colors.primary };
    border-left: .1em solid ${props => props.invert ? '#fff' : theme.colors.primary };
    transform: translate(.3em, .3em) rotate(-45deg) scale(1);
    opacity: 0;
    transition: all 200ms ease-in-out;
  }
  .check__label {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center; 
  }
`

const CheckBox = ({ children, required, invert }) => (
  <Wrapper invert={invert}>
    <label className="check__label">
      <input type="checkbox" name="accepto" id="accepto" className="check__input" value="1" required={required ? true : false }  />
      <span className="check__decor"></span> 
      {children}
    </label>
  </Wrapper>
)

export default CheckBox